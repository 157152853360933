<script setup lang="ts">
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EmptyState from '/~/components/empty-state/empty-state.v2.vue'
import PaymentMethodList from '/~/components/payments/list/default.v4.vue'
import { useCheckoutMethodComponent } from '/~/views/checkout.v2/views/checkout/views/checkout-method.component'

const {
  backRoute,
  payment,
  loading,
  isPaymentMethodsEmpty,
  onAddNewPaymentMethodClick,
  onSelect,
} = useCheckoutMethodComponent()
</script>

<template>
  <base-aside-page
    title="Select payment method"
    no-padding
    shadow
    :back="backRoute"
    :action="payment.canAddPaymentMethods && onAddNewPaymentMethodClick"
  >
    <div class="flex h-full w-full flex-col items-center overflow-hidden">
      <base-loader v-if="loading" class="mt-12" />
      <empty-state
        v-else-if="isPaymentMethodsEmpty"
        class="m-auto flex h-full w-full flex-col items-center justify-center px-4"
      >
        <template #icon>
          <base-icon
            svg="heroicons/solid/credit-card"
            :size="64"
            class="mr-6 text-primary"
          />
        </template>
        <template #title>No payment methods available</template>
        <template #description>
          <div>
            Securely store and manage your preferred credit and debit cards for
            instant use.
          </div>
          <base-button
            v-if="payment.canAddPaymentMethods"
            class="mt-4"
            @click="onAddNewPaymentMethodClick"
          >
            Add Payment Method
          </base-button>
        </template>
      </empty-state>

      <payment-method-list
        v-else
        class="w-full overflow-auto p-4"
        @selected="(method) => onSelect(method)"
      />
    </div>
  </base-aside-page>
</template>
