<script setup lang="ts">
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EmptyState from '/~/components/empty-state/empty-state.v2.vue'
import PaymentMethodList from '/~/components/payments/list/default.v4.vue'
import { useCheckoutMethodComponent } from '/~/views/checkout.v2/views/checkout/views/checkout-method.component'

const {
  backRoute,
  payment,
  loading,
  isPaymentMethodsEmpty,
  onAddNewPaymentMethodClick,
  onSelect,
} = useCheckoutMethodComponent()
</script>

<template>
  <div
    class="flex h-full w-full flex-col items-center overflow-hidden px-10 py-8"
  >
    <div class="w-full max-w-xl px-2 pb-8 pl-3 pr-6">
      <base-back
        v-analytics="{
          pageGroup: 'Make a payment',
          page: 'Select payment method',
          cta: 'Back',
        }"
        class="block leading-5"
        :to="backRoute"
      />
    </div>

    <div
      class="mb-8 flex w-full max-w-xl shrink-0 flex-wrap justify-between px-2"
    >
      <span v-if="!isPaymentMethodsEmpty" class="text-2xl font-bold leading-7">
        Select payment method
      </span>

      <base-button
        v-if="!isPaymentMethodsEmpty && payment.canAddPaymentMethods"
        v-analytics:click="{
          pageGroup: 'Make a payment',
          page: 'Select payment method',
          cta: 'Add Payment Method',
        }"
        look="link"
        :disabled="payment.submitting"
        @click="onAddNewPaymentMethodClick"
      >
        + Add Payment Method
      </base-button>
    </div>
    <base-loader v-if="loading" size="xl" />
    <empty-state
      v-else-if="isPaymentMethodsEmpty"
      class="m-auto flex h-full flex-col items-center justify-center px-4"
    >
      <template #icon>
        <base-icon
          svg="heroicons/solid/credit-card"
          :size="64"
          class="mr-6 text-primary"
        />
      </template>
      <template #title>No payment methods available</template>
      <template #description>
        <div>
          Securely store and manage your preferred credit and debit cards for
          instant use.
        </div>
        <base-button class="mt-4" @click="onAddNewPaymentMethodClick">
          Add Payment Method
        </base-button>
      </template>
    </empty-state>
    <payment-method-list
      v-else
      class="w-full max-w-xl overflow-y-auto overflow-x-hidden px-2"
      @selected="(method) => onSelect(method)"
    />
  </div>
</template>
