import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { PaymentMethod } from '/~/types/api'
import bottomSheet from '/~/core/bottom-sheet'
import ui from '/~/core/ui'
import { camelToKebab } from '/~/utils/format'
import { useAccess } from '/~/composables/access/use-access'
import { useAddresses } from '/~/composables/addresses'
import { useCheckoutReactive, useCheckoutRoutes } from '/~/composables/checkout'
import {
  FlowType,
  getFlowTypeKey,
} from '/~/composables/checkout/checkout-types'
import {
  PaymentMethodMappedType,
  PaymentMethodType,
  useFlowPaymentMethods,
  usePayId,
} from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export function useCheckoutMethodComponent() {
  const router = useRouter()
  const route = useRoute()
  const { withAccessCheck } = useAccess()

  const {
    payment,
    currentFlowType,
    isQuickBuyCurrentFlowType,
    isStatementCurrentFlowType,
    isPaymentCurrentFlowType,
    isBatchOrderCurrentFlowType,
  } = useCheckoutReactive()
  const { getCurrentFlowRouteName } = useCheckoutRoutes()
  const { fetching, isPaymentMethodsEmpty } = useFlowPaymentMethods(
    currentFlowType.value,
    (payment.value as any).payeeId
  )
  const { splitPaymentOrderAllowed, splitPaymentCombinationsAllowed } =
    useProvider()
  const { getBurnPointsRate } = usePoints()
  const { checkAddresses } = useAddresses()
  const { payIdManager } = usePayId()

  const loading = computed(
    () =>
      fetching.value ||
      (isQuickBuyCurrentFlowType.value && payment.value.isQuickBuyInitializing)
  )

  const redirect = computed(() => {
    return {
      name:
        route.meta?.next?.name ||
        getCurrentFlowRouteName('checkout') ||
        route.meta?.back?.name,
      params: route.params,
      query: route.query,
    }
  })

  const backRoute = route.meta?.back || redirect.value

  try {
    if (isQuickBuyCurrentFlowType.value) {
      payment.value.initItems({
        type: route.query?.type ?? null,
        query: route.query ?? null,
      })
    }
  } catch (e) {
    console.error(e)
    router.push({ name: 'home' })
  }

  function onAddNewPaymentMethodClick() {
    withAccessCheck({
      callback: () => {
        const addPaymentMethodRoute = route.meta?.addPaymentMethods ?? {
          hash: '#profile-payment-methods-add',
          query: router.currentRoute.query,
          params: {
            source: payment.value.orderType,
          },
        }

        router.push(addPaymentMethodRoute)
      },
      config: {
        actionType: 'paymentMethods',
      },
    })
  }

  function showSelectDateBs(currentFlowTypeKey: string) {
    bottomSheet.show('select-date', {
      props: {
        onConfirm: async () => {
          router.push({
            name: `${currentFlowTypeKey}-checkout-summary`,
          })
        },
      },
    })
  }

  async function onSelect(method: PaymentMethod) {
    const flowType = isQuickBuyCurrentFlowType.value
      ? FlowType.purchase
      : currentFlowType.value

    const currentFlowTypeKey = camelToKebab(
      getFlowTypeKey(currentFlowType.value)
    )
    const mappedType = PaymentMethodMappedType[method.type]

    let calculatedAmount

    if (
      splitPaymentOrderAllowed(flowType) &&
      splitPaymentCombinationsAllowed(
        mappedType,
        payment.value.selectedPaymentMethods
      )
    ) {
      calculatedAmount =
        payment.value.subTotalWithProgramFees -
        payment.value.payWithPoints -
        payment.value.payWithEwallet -
        payment.value.payWithCoupons
    } else {
      calculatedAmount = payment.value.subTotalWithProgramFees
      payment.value.resetPaymentMethods()
    }

    const payload = {
      ...method,
      calculatedAmount,
    }

    if (payload.type === PaymentMethodType.creditCard) {
      payment.value.selectedCard = payload
    } else if (payload.type === PaymentMethodType.bankAccount) {
      payment.value.selectedBankAccount = payload
    } else if (payload.type === PaymentMethodType.points) {
      payment.value.selectedPoints = {
        ...payload,
        ...payment.value.selectedPoints,
        usePoints: Math.ceil(
          payment.value.subTotalWithProgramFeesNoCoupons /
            getBurnPointsRate(flowType)
        ),
        calculatedAmount: payment.value.subTotalWithProgramFeesNoCoupons,
      }
    } else if (payload.type === PaymentMethodType.payId) {
      payment.value.selectedPayId = {
        ...payIdManager.payId,
        ...method,
        ...payment.value.selectedPayId,
        calculatedAmount: payment.value.subTotalWithProgramFeesNoCoupons,
      }
    } else {
      return
    }

    payment.value.getTransactionFees()

    if (method.type === PaymentMethodType.points && ui.mobile) {
      router.push({ name: `${currentFlowTypeKey}-checkout-summary` })
    } else if (isStatementCurrentFlowType.value && ui.mobile) {
      if (payment.value.isSchedulingAllowed) {
        showSelectDateBs(currentFlowTypeKey)
      } else {
        router.push({ name: `${currentFlowTypeKey}-checkout-summary` })
      }
    } else if (isBatchOrderCurrentFlowType.value && ui.mobile) {
      router.push({
        name: `${currentFlowTypeKey}-${payment.value.subFlowType}-checkout-summary`,
      })
    } else if (isPaymentCurrentFlowType.value && ui.mobile) {
      showSelectDateBs(currentFlowTypeKey)
    } else {
      router.push(redirect.value)
    }
  }

  onMounted(() => {
    checkAddresses()
  })

  return {
    backRoute,
    payment,
    loading,
    isPaymentMethodsEmpty,
    onAddNewPaymentMethodClick,
    onSelect,
  }
}
